import React, { Component } from "react"
import {Link} from 'gatsby'

import Layout from "../components/layout"
import GatedContent from "../components/gatedcontent"


const contentlist = [
			{
				name: "Intefacing with SSH Servers",
				sublist: [
					{
						name: "Command-line access to an AWS Server",
						url: "ssh-to-aws-server"
					},
					{
						name: "Notepad++",
						url: "ssh-notepad-pplus"
					},
					{
						name: "Sublime",
						url: "ssh-sublime"
					},
				]
			},
			{
				name: "Others",
				sublist: [

					{
						name: "Remote Gatsby Develop",
						url: "remote-gatsby-develop"
					},
					{
						name: "Rename Xcode Project",
						url: "rename-xcode-project"
					},
				]
			}
	];

export default class GuideIndex extends Component {
	render() {
		return (
			<Layout showtitle={true} activePath='guide' pageTitle="Guides" privatePage={true}>
				<GatedContent>
					<div className={"margin-content"}>
						<ul>
						{
							contentlist.map((heading,headingindex) => {
									return <li>{heading.name}<ul>
									{
										heading.sublist.map((data,index) => {
												return <li key={"listitem"+headingindex+"-"+index}><Link to={data.url}>{data.name}</Link></li>;
										})
									}
									</ul></li>
							})
						}
						</ul>
					</div>
				</GatedContent>
			</Layout>
		)
	}
}



